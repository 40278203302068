@charset "utf-8";

@import "so-simple/functions";
@import "so-simple/variables";
@import "so-simple/notices";

/* Colors */
$base-color: #404040 !default;
$text-color: #404040 !default;
$accent-color: #0a71e2 !default;
$nav-color: #fff !default;
$background-color: #fff !default;
$nav-background-color: #626262 !default;
$code-background-color: tint($base-color, 95%) !default;
$border-color: tint($base-color, 80%) !default;

$primary-color: #fff !default;
$success-color: #34c759 !default;
$warning-color: #ffcc00 !default;
$danger-color: #ff3b30 !default;
$info-color: #5ac8fa !default;

/* Syntax highlighting (base16) colors */
$base00: #fafafa !default;
$base01: #073642 !default;
$base02: #586e75 !default;
$base03: #657b83 !default;
$base04: #839496 !default;
$base05: #586e75 !default;
$base06: #eee8d5 !default;
$base07: #fdf6e3 !default;
$base08: #dc322f !default;
$base09: #cb4b16 !default;
$base0a: #b58900 !default;
$base0b: #859900 !default;
$base0c: #2aa198 !default;
$base0d: #268bd2 !default;
$base0e: #6c71c4 !default;
$base0f: #d33682 !default;

@import "so-simple/skin";
